import React, {useEffect} from 'react';  
import {HelmetHomeSEO} from '../seo'; 
import Footer from './Footer.js';

function PageNotFound() {

    useEffect(()=>{ 
        window.scrollTo(0, 0);
        const script = document.createElement('script');
        script.src =
          'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js';
        document.body.appendChild(script);
      }); 


  return (
    <>

     <HelmetHomeSEO />  

        <div className="wrapper--50">    

            <div className="row page404">
                <div className="column">  
                        <lottie-player src="https://assets5.lottiefiles.com/temp/lf20_0txt7u.json"  background="transparent"  speed="1"  style={{'width': '100%', 'height': '400px'}}  loop  autoplay></lottie-player> 
 
                        <p>Not all who wander are lost. But you certainly are. <a rel="noopener noreferrer" href="/">Go to Homepage.</a> </p> 
                    
                </div> 
            </div>   

            <Footer compressed={true} />

        </div> 
        
    </>
  );
}

export default PageNotFound;
