import React from 'react';   
import {Link } from 'react-router-dom';
import {BlogList} from '../../seo/';

const BlogListing = ({ignoreID,category}) =>{ 

  const allCategory = `all`;
    const selectedCat = category ? category : allCategory
    return (
        <div className="row">
        <div className="column"> 
          {BlogList.map((item,index)=>{ 
            if(ignoreID && ignoreID == item.id){ 
              return null;
            }
            if((selectedCat == item.category || selectedCat ==  allCategory) && item.active){
              return(
                <Link key={index} to={`/blog/${item.url}`} className="blog-list-item">   
                    <h5>{item.title}</h5> <p>{item.date}</p>
                </Link>  
              ) 
            }
            else{
              return null
            }

          })}
        </div> 
    </div>  
      )
  }


  export default BlogListing