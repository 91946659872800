import React from 'react'; 
import { Link, useHistory} from 'react-router-dom';  
function NavBar(){

    const history = useHistory();  
    const handleNavClick = (url) => {
      history.push(url); 
    };


    return (
      <nav className="navigation">
          <section className="container">
              <Link className="navigation-title" to="/" title="Vishnu Baliga">
                  <h1 className="title">Vishnu Baliga</h1>
                </Link>
                <ul className="navigation-list float-right"> 
                    <li className="navigation-item" onClick={()=>handleNavClick('/blog')} >
                            <Link className="navigation-link" to="/blog/">Blog</Link>
                    </li>
                    <li className="navigation-item">
                        <a className="navigation-link" data-popover="">Contact</a>
                        <div className="popover" id="popover-support">
                            <ul className="popover-list">
                                <li className="popover-item"><a className="popover-link" href="https://www.linkedin.com/in/vishnubaliga/"  rel="noopener noreferrer" target="_blank" title="LinkedIn">LinkedIn</a></li>
                                <li className="popover-item"><a className="popover-link" href="https://twitter.com/VishnuBaliga"  rel="noopener noreferrer" target="_blank" title="Twitter">Twitter</a></li>
                                <li className="popover-item"><a className="popover-link" href="https://www.instagram.com/vishnu_baliga/"  rel="noopener noreferrer" target="_blank" title="Instagram">Instagram</a></li>
                                <li className="popover-item"><a className="popover-link" href="https://dev.to/vishnubaliga"  rel="noopener noreferrer" target="_blank" title="Dev.to">DEV</a></li>
                                <li className="popover-item"><a className="popover-link" href="https://github.com/VishnuBaliga" rel="noopener noreferrer"  target="_blank" title="Github">Github</a></li>
                                <li className="popover-item"><a className="popover-link" href="mailto:baliga.vishnu@gmail.com?subject=Contact Form!&body=Hi Baliga!"  rel="noopener noreferrer" title="Email">Email</a></li>
                            </ul>
                        </div>
                    </li>
                </ul> 
          </section>
          </nav>
    )
  }

  export default NavBar;
