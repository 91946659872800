import React from 'react';   
import {BlogList} from '../../seo';  
import Subscribe from './subscribe'; 
import { DiscussionEmbed } from 'disqus-react';



function BlogFooter({BlogId}) {

    // console.log('datails');
    // console.log('url',BlogList[BlogId].url);
    // console.log('id',BlogList[BlogId].id);
    // console.log('title',BlogList[BlogId].title);  

    return(
        <>
        <div className="divider--top">
            <div className="row">
                <div className="column column-75 center"> 

                    <DiscussionEmbed
                    shortname='vishnubaliga'
                    config={
                        {
                            url: `https://vishnubaliga.com/blog/${BlogList[BlogId].url}`,
                            identifier: `${BlogList[BlogId].id}`,
                            title: BlogList[BlogId].title,
                        }
                    }
                />
                </div>
            </div> 
        </div>
    <Subscribe ignoreID={BlogList[BlogId].id} category={BlogList[BlogId].category} />
    </>

    )
}

export default BlogFooter 