

import React,{useEffect} from 'react';  
import {Helmet} from 'react-helmet'; 
import BlogListing from './BlogListing';
import MailchimpSubscribe from "react-mailchimp-subscribe";
 
const url = `https://vishnubaliga.us19.list-manage.com/subscribe/post?u=713e7c7aef6d8298bb7cd0892&amp;id=36efedac38`;

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = (e) =>{
    e.preventDefault();
      email &&
      name &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        NAME: name.value
      });
    }
  
    const sending = (status === 'sending');
    const error = (status === 'error');
    const success = (status === 'success');
    return (
     <> 
            {!success && <form onSubmit={(e) => submit(e)}>
                <div className="row margin--zero">
                        <div className="column column-40"> 
                                <input ref={node => (name = node)} disabled={sending} type="text" placeholder="Your name" />
                        </div>
                        <div className="column column-40"> 
                                <input ref={node => (email = node)} disabled={sending} type="email" placeholder="Your email" />
                        </div>
                        <div className="column"> 
                                <button className="" onClick={(e) => submit(e)} disabled={sending}>
                                {`Subscribe` }
                                </button>
                        </div>
                </div>
            </form>}

        <div className="row margin--zero">
            <div className="column"> 
            {error && (
            <div className="error-box" dangerouslySetInnerHTML={{ __html: message }} />
            )}
            {success && (
            <div className="success-box" dangerouslySetInnerHTML={{ __html: message }} />
            )}
            </div>
        </div>
    </>
    );
  };

function Subscribe({ignoreID,category}) {
 
    return (
        <div className="divider--top">
        <div className="row">
        <div className="column column-75 center"> 
            <div className="subscription-container">
                <div className="row">
                  <div className="column column-75"> 
                    <h3>Subscribe to the Newsletter</h3>
                    <p>Receive quality articles written by Vishnu Baliga, you’ll find content pieces ranging from CSS, HTML, Browsers, Marathons, Fitness. Pretty much everything where my passion lies.</p>
                  </div>
                </div> 

                 <MailchimpSubscribe
                        url={url}
                        render={({ subscribe, status, message }) => (
                         <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                        />
                        )}
                    />  
            </div>
        </div>
    </div>
 
        <div className="row">
            <div className="column column-75 center"> 
                <div className="related-posts-container"> 
                    <h2>Related posts</h2>
                    <BlogListing ignoreID={ignoreID} category={category} />
                </div>
            </div>
        </div> 
</div> 
    );
  }
  
  export default Subscribe;
  