import React,{useEffect} from 'react';    
import {BlogListHelmet,BlogList} from '../../seo';    
import BlogFooter from './BlogFooter';
import {Link } from 'react-router-dom';

const blogID = 5;
const ImgBaseUrl = `/img/blog/blog-106`;
const ImgBaseClass = `blog-img--large`;

 
function Blog4() {  
 
  useEffect(() => {
    (async () => {
        if ('loading' in HTMLImageElement.prototype) {
            const images = document.querySelectorAll("img.lazyload");
            images.forEach(img => {
                img.src = img.dataset.src;
            });
        } else {
          const script = document.createElement('script');
          script.src =
            'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
          document.body.appendChild(script);
        }
  })();
  });

  return (
    <div className="blog-container">  
    
    {/* importing SEO Details */}
    {BlogListHelmet(blogID)}

    <div className="row">
        <div className="column column-75 center title-container"> 
          <h1>{BlogList[blogID].title}</h1>
          <p>{BlogList[blogID].date}</p> 
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center"> 
            <p>COVID 19 has left the world in a mélange of uncertainty but there is hope as empathy is reigning supreme for corporate entities have donated their resources to help put the world back on track. From tobacco companies working on vaccines to perfumers manufacturing sanitizers, industries across the spectrum have chipped in towards the effort. There is, however, one entity that is appropriately poised to be a part of the undertaking: Start-ups, particularly tech start-ups.</p>
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center"> 
            <p>The appeal of start-ups in a situation such as this arises due to several innate factors. For starters, start-ups possess a degree of flexibility that larger firms are unable to accommodate in their structure. There are fewer decision makers involved and the teams are often composed of relatively nuclear units. Therefore, it is easier for a start-up to modulate their daily activities towards the relief endeavors that are vital to handling the crisis as opposed to multi-tiered million dollar companies whose mechanism is of a more rigid disposition. Moreover, the products can be meted out at a brisker pace in start-ups owing to its relative freedom from administrative scruples.</p>
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center"> 
            <p>I was part of a similar endeavor in 2015 when the floods struck Chennai. It was a situation that bore a certain resemblance to the current state of things, albeit at a much localized scale. I was working for a start-app called <strong>Scappi</strong> which was essentially a location based app designed to share surplus food. When the Chennai floods wreaked havoc and left many stranded, my team had the idea of repurposing the app to aid relief measures. The app lets volunteers and NGOs upload their locations to the app and thus organize the relief works to help it reach its maximum efficiency. The functions of the app were even utilized by the army and navy for their rescue operations. </p>
            
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center">  
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/scappi-hindu-newspaper.jpg`} alt="Scappi for Chennai featured in Hindu Newspaper" />
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/scappi-sashi-tharoor.jpg`} alt="Scappi for Chennai tweet by Sashi Tharoor" />
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center"> 
            <p>The modified app was rolled out in under three days and was available on Playstore. There were a significant number of downloads and news of the app was circulated swiftly via social media. The primary reason for bringing this up is to illustrate the efficacy of a tech start-up in a moment of crisis. The app was introduced in 2015 when start-ups were just starting to gain momentum in India and the widespread use of mobile applications was yet to be realized. Fast forward half a decade and the prominence of apps and the presence of start-ups in the national scene have skyrocketed. Therefore, start-ups are ideally positioned to help relief measures and provide a relative state of normalcy amid the lockdown.</p>
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center">  
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/scappi-vishnu-baliga-post.jpg`} alt="Scappi for Chennai tweet by Vishnu Baliga" />
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center"> 
            <p>I hope that start-ups take upon the responsibility with due diligence and the efforts done during the Chennai Floods can be extrapolated to a national level undertaking.</p>
        </div> 
    </div> 

    <BlogFooter BlogId={blogID} />

    </div>
  );
}

export default Blog4;
