import React,{useEffect} from 'react';    
import {BlogListHelmet,BlogList} from '../../seo';    
import BlogFooter from './BlogFooter';
import {Link } from 'react-router-dom';

const blogID = 3;
const ImgBaseUrl = `/img/blog/blog-104`;
const ImgBaseClass = `blog-img--large`;

 
function Blog4() {  
 
  useEffect(() => {
    (async () => {
        if ('loading' in HTMLImageElement.prototype) {
            const images = document.querySelectorAll("img.lazyload");
            images.forEach(img => {
                img.src = img.dataset.src;
            });
        } else {
          const script = document.createElement('script');
          script.src =
            'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
          document.body.appendChild(script);
        }
  })();
  });

  return (
    <div className="blog-container">  
    
    {/* importing SEO Details */}
    {BlogListHelmet(blogID)}

    <div className="row">
        <div className="column column-75 center title-container">

        <h1>{BlogList[blogID].title}</h1>
        <p>{BlogList[blogID].date}</p>
        
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center"> 
            <p>Data analytics is one of the most critical aspects in the arsenal of the corporate world. Surveys play a major role in collecting data making it a vital component of business analytics. There have been numerous innovations to enhance the experience of survey takers in order to ensure reliable data finds its way in. Designers have employed techniques that make use of colors and patterns to engage the user in the survey. However, that undertaking was severely limited by the existing resources available in UI and UX design.</p>
            <video width="100%" height="460" src={`${ImgBaseUrl}/vishnubaliga-video-survey-demo-1.mp4`} autoplay controls />
            {/* <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/vishnubaliga-video-survey-example-1.gif`} alt="vishnubaliga-video-survey-example-1" /> */}
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center">  
            <p>As a front-end lead at <a rel="noopener noreferrer" href="http://surveysparrow.com/" target="_blank">SurveySparrow</a>, a SaaS Startup that seeks to make surveys better, I was often faced with the task of finding a way around this conundrum. </p>  
            <p>Well if the bathtub was the setting for Archimedes’ inspiration, mine was a music concert. </p>  
            <p>A while ago, I attended a concert with my friends in Goa (we all need to break our routine once in a while, right!). I was pumped up at the event and the artist was out of this world. His energy meshed well with the crowd and he was able to maintain a rapport with his audience for the entirety of his stage time. The crowd’s complete immersion throughout the act got me thinking: how can I ensure this sort of continued zest in a survey?  That’s when I thought, wait a moment. </p>  
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center">  
        <video width="100%" height="460" src={`${ImgBaseUrl}/vishnubaliga-video-survey-demo.mp4`} autoplay controls />
        {/* // <iframe width="100%" height="460" src="https://www.youtube.com/embed/x6iXynL-gKM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center">  
            <p>I hit upon the idea of using embedded videos and visuals coupled with appealing audio to create an atmosphere that will hold the attention of the user and make them interact with the survey in a more dynamic capacity. The concept was to create a sense of conversation with the user as opposed to the typically mundane questionnaire format. In addition to refining user experience, video surveys will improve brand awareness whilst educating the audience about the survey’s purpose constructively. If that is not sufficient reason to shift to video surveys, just consider the sprinkle of fun you could add in what would have been an otherwise dull survey.</p>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/vishnubaliga-video-survey-example-2.gif`} alt="vishnubaliga-video-survey-example-1" />
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center">  
            <p>In a world chasing the next big thing, it is important to stay ahead of the curve. Streamlined objectives and creative thinking are thus vital to ensure that we maintain our competitive edge. It has been proven that video and audio inputs stimulate the brain to a higher degree than typed formats. <a rel="noopener noreferrer" target="_blank" href="https://surveysparrow.com/features/video-surveys/">Video surveys</a> are just the tip of the iceberg. </p> 
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center">  
            <p>Innovations like audio interactions can very well elevate the user experience to a level akin to human conversation. The journey to developing next-generation surveys is not a sprint but a <Link to="/blog/5-ways-with-which-i-trained-a-team-for-their-first-marathon">Marathon</Link>. And, that's what I've set out for now!</p>
        </div> 
    </div> 
     


    <BlogFooter BlogId={blogID} />

    </div>
  );
}

export default Blog4;
