import React,{useEffect} from 'react';    
import {BlogListHelmet,BlogList} from '../../seo';    
import BlogFooter from './BlogFooter';

const blogID = 2;
const ImgBaseUrl = `/img/blog/blog-103`;
const ImgBaseClass = `blog-img--large`;

 
function Blog3() {  
 
  useEffect(() => {
    (async () => {
        if ('loading' in HTMLImageElement.prototype) {
            const images = document.querySelectorAll("img.lazyload");
            images.forEach(img => {
                img.src = img.dataset.src;
            });
        } else {
          const script = document.createElement('script');
          script.src =
            'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
          document.body.appendChild(script);
        }
  })();
  });

  return (
    <div className="blog-container">  
    
    {/* importing SEO Details */}
    {BlogListHelmet(blogID)}

    <div className="row">
        <div className="column column-75 center title-container">

        <h1>{BlogList[blogID].title}</h1>
        <p>{BlogList[blogID].date}</p>
        
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center">

       <p>I have tried my hand at a myriad of physical activities ranging from MMA to swimming and after a bit of experimentation, I found the ideal fit for me: Marathon running. It did wonders for my fitness whilst giving me a confidence boost to explore more about the sport. As my experience in marathons progressed, I wanted to share what I had learned and I was, therefore, delighted to have the opportunity to train a group of individuals for an event.</p>
       <p>Let me take you on a ride-along on how I trained a team for their first marathon.</p> 
       <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/TCS-marathon-Vishnu-baliga.jpg`} alt="Marathon Vishnu Baliga" />
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h2 className="title-highlight">How it began</h2>
            <img src="" alt="" />
            <p>Barring the few minutes of cardio at the gym, I was never much of a runner nor did I have any prior experience in it professionally or semi-professionally. I took up running as a fitness goal and wanted to work on my endurance to attune my body to run longer distances. I shook off the initial misgivings and dedicated myself to achieving my fitness goals. With this decision came lifestyle changes and I found myself saying no to processed foods and attempting to eat healthier. My daily schedules were also revamped to accommodate my objectives and weekend partying was soon replaced by stringent running regimens.</p>
            <p>I set aside an hour daily to train for a period of two months. As my endurance and confidence started taking an upward trajectory, I decided to enlist for my first marathon. I started to include a long run/marathon session every Sunday in addition to my usual regimen and began conquering formidable targets every passing week. I began posting updates of my training process on social media. The rigors of the training regimen had a marked improvement on my physique. This piqued the curiosity of my peers at work and they were eager to know more about the process. A few of them were intrigued by it and wanted to be a part of the endeavor resulting in the formation of a marathon team.  </p>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/marathon-collage-vishnu-baliga-1.png`} alt="marathon-collage-vishnu-baliga" />
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h2 className="title-highlight">Devise a Training Plan</h2>
            <img src="" alt="" />
            <p>My first priority was to formulate a training plan that will be tailored to the team as they were all newcomers to the running arena. The priority was to ease them into the process and steadily build on that momentum.. The key was to set targets and stick to them.</p>
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <p>I employed the following techniques to achieve our targets:</p> 
            <h3>1. First Steps</h3>   
            <p>Since my team members were all coworkers, we used to train after work in the car park at our office campus. We started with baby steps like beginning with a 5-minute warm-up followed by a 15-minute run and then transitioning to a 10-minute warm-up followed by a 25-minute run and so on.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>2. Break Down The Distance</h3>   
            <p>Numbers like 21 and 42 kilometers can sound quite intimidating and thus it was important to set fractions of that target and work on that systematically. As the body adapts, the targets get steeper until eventually they are all capable to run half or full marathons.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>3. Develop Proper Breathing Techniques</h3>   
            <p>As anyone who has been part of a marathon will attest, the crux to successfully completing a marathon is the habitual development of <a href="https://www.runnersworld.com/training/a20821630/what-breathing-tells-you-about-running-intensity/" target="_blank">breathing techniques</a>. Most people have a certain breathing rhythm that they subconsciously follow whilst performing physical exertions and it tends to vary with the complexity and length of time the exertion is pursued. In running, this breathing rhythm, particularly the ratio of the strides to an intake of breath, can be modified so that the athlete can cover longer distances without sacrificing on speed. The right use of this step to breath ratio can drastically improve the performance of the runner. I trained my team to use varying step: breath ratios and each member settled on the ones they were most comfortable with.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>4. Pick Up The Pace</h3>   
            <p>Once my team built the endurance to run the required distance, the next step was to boost their pace so that we can attain a competitive perspective. We organized mini-tournaments amongst us to put ourselves in the competitive frame. Tabs were kept on the running time and a cumulative effort was made by the team to shorten it by a few minutes each time round. The idea was to translate the momentum into a productive output.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>5. Distract The Mind</h3>   
            <p>Running a marathon is a time-intensive undertaking. Thus, it is natural to lose motivation if a runner keeps on dwelling at the distance remaining or the soreness of his legs. I found music to be an effective distraction to keep the morale high and ensured that my team picked up on it as well. I personally prefer listening to classical fusions or psytrance for a brisker beat but to each their own.</p>             
            <p>The above training plan seeks to boost the mental and physical endurance of the runner in addition to familiarizing them with the finer aspects of the sport. I have tried to utilize my experience to educate and inspire my team and I am proud of the progress we have made. I can attest that each member of the team was able to cover a distance of 10 kilometers in under an hour towards the end of the program.</p>             
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center">
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/marathon-collage-vishnu-baliga-2.png`} alt="marathon-collage-vishnu-baliga" />
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/marathon-collage-vishnu-baliga-3.png`} alt="marathon-collage-vishnu-baliga-2" />
            <p>Since the team’s inception, we have participated in 6 marathons together. We have our sights set on <a href='https://spicecoastmarathon.com/' target="_blank">Spice Coast Marathon 2020 - Kochi</a> and are currently training for the same. We encourage and support each other and push ourselves to beat our personal records and excel at events. Together, We Conquer!</p>             
        </div> 
    </div> 


    <BlogFooter BlogId={blogID} />

    </div>
  );
}

export default Blog3;
