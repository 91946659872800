import React from 'react'; 
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from './pages/NavBar';
import Home from './pages/Home';
import Blog from './pages/blogs/';  
import PageNotFound from './pages/PageNotFound';  

function App() {
  return (
    <main className="wrapper">
      <div className="wrapper-container">
        <div className="container"> 

        <BrowserRouter> 
          <NavBar /> 
          <Switch>
            <Route exact path="/" component={Home}/> 
            <Route path="/blog" component={Blog}/>    
            <Route path="/404" component={PageNotFound}/>   
            <Redirect to='/' />
          </Switch>
        </BrowserRouter>
        </div> 
      </div> 
    </main>
  );
}

export default App;
