import React,{useEffect} from 'react';   
import {BlogListHelmet,BlogList} from '../../seo';   
import BlogFooter from './BlogFooter';

const blogID = 0;
const CanIUseEmbed1 = () => {
  return(
    <> 
      <p className="ciu_embed" data-feature="prefers-color-scheme" data-periods="future_1,current,past_1,past_2" data-accessible-colours="false">
      {/* <a target="_blank" href="http://caniuse.com/#feat=prefers-color-scheme">
      <picture>
      <source type="image/webp" srcSet="https://caniuse.bitsofco.de/image/prefers-color-scheme.webp" />
      <img src="https://caniuse.bitsofco.de/image/prefers-color-scheme.png" alt="Data on support for the prefers-color-scheme feature across the major browsers from caniuse.com" />
      </picture>
      </a> */}
      </p>
    </>
  )
}
function Blog1() { 

  useEffect(() => {  

      (async () => { 
        for (var e = document.getElementsByClassName("ciu_embed"), t = 0; t < e.length; t++) {
          var a = e[t],
              i = a.getAttribute("data-feature"),
              n = a.getAttribute("data-periods"),
              s = a.getAttribute("data-accessible-colours") || "false",
              r = a.getAttribute("data-image-base") || "none";
          if (i) {
              var o = "https://caniuse.bitsofco.de/embed/index.html",
                  d = '<iframe src="' + o + "?feat=" + i + "&periods=" + n + "&accessible-colours=" + s + "&image-base=" + r + '" frameborder="0" width="100%" height="400px"></iframe>';
              a.innerHTML = d
          } else a.innerHTML = "A feature was not included. Go to <a href='https://caniuse.bitsofco.de/#how-to-use'>https://caniuse.bitsofco.de/#how-to-use</a> to generate an embed."
      }
      var c = window.addEventListener ? "addEventListener" : "attachEvent";
      (0, window[c])("attachEvent" == c ? "onmessage" : "message", function(t) {
          var a = t.data;
          if ("string" == typeof a && a.indexOf("ciu_embed") > -1)
              for (var i = a.split(":")[1], n = a.split(":")[2], s = 0; s < e.length; s++) {
                  var r = e[s];
                  if (r.getAttribute("data-feature") === i) {
                      var o = parseInt(n) + 30;
                      r.childNodes[0].height = o + "px";
                      break
                  }
              }
      }, !1)
  })();

   
    
  });

  return (
    <div className="blog-container">  
    
    {/* importing SEO Details */}
    {BlogListHelmet(blogID)}

    <div className="row">
        <div className="column column-75 center title-container">

        <h1>{BlogList[blogID].title}</h1>
        <p>{BlogList[blogID].date}</p>
        
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center">

       <p>As the infamous Darth Vader once said, “You don’t know the power of the dark side.” </p>
       <p>2019 brought justice to Vader when dark mode took over our apps and the craze is yet to plateau. From Instagram to Youtube, our beloved apps have jumped on the bandwagon and it won’t be long before the Sith triumphs. </p>

        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h2>What is Dark Mode?</h2>
            <p>For those fond of technical jargon, Wikipedia defines dark mode as the “color scheme that uses light-colored text, icons, and graphical user interface elements on a dark background.”</p>
            <p>Simply put, dark mode is just as the name suggests: a darker thematic interface for your apps. However, there’s more to it than a gothic premise.</p>
        </div> 
    </div>


    <div className="row">
        <div className="column column-75 center"> 
            <h2>Why is Dark Mode all the rage?</h2>
            <ul>
              <li>Who doesn’t like a tall, ‘Dark’ Stranger.   The dark mode has a certain sense of aesthetic appeal that's charismatic to all; a congruence that's hardly kaleidoscopic.</li>
              <li>For a generation that spends more time in front of screens than people, dark mode takes a minimal toll on your eyes. God bless thy eyes!</li>
              <li>It helps maintain the circadian rhythm of users.</li>
              <li>Finally, it drains less of your battery. Now, that’s a deal-sealer, ain’t it!</li>
            </ul>
           
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
           <h2 className="title-highlight">What are the ways to switch your website to Dark Mode?</h2>
           <p>‘Ready, you are’, so let’s take a look at three easy ways to implement dark mode for websites.</p>
           <h3>1. Check for OS-level preferences</h3>
           <p>Before getting into the nitty-gritty of the affair, it is vital to check the parameters for the operating system. For instance, Whatsapp in certain android versions gave the users the option to switch to night mode whereas iOS dark mode was set as default on its devices for the app. This should be taken into account before adding the codes for the same. The code to implement the dark theme is shown below in both CSS and Js.</p>

           <h4>Using CSS</h4>
     
             <pre>
             {
`
    /* Light mode */
    @media (prefers-color-scheme: light) {
        body {
            background-color: #fff;
            color: black;
        }
    }
    /* Dark mode */
    @media (prefers-color-scheme: dark) {
        body {
            background-color: #000;
            color: white;
        }
    }

`
              }
             </pre>
       

           <h4>Code if Js detection is needed</h4>
           
             <pre>
             {
`
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) { 
      // dark mode 
    }

`
              }
             </pre>
      
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center"> 
        <h3>2. Choose a theme based on the time of day</h3>
        <p>In the age of convenience, a full-time dark mode may not be a viable option for users of your website. Your website’s flair would be spruced up immensely by using a theme that mirrors the time of day. In this manner, the early birds are taken care of and so are the night owls! It’s only sensible to maintain this natural equilibrium. </p>
          <h4>The following code is a good place to start.</h4>
          <pre>
{
`
  function returnThemeBasedOnTime(){
    const date = new Date()
    const hour = date.getHours()
    if (hour < 5 || hour > 20) return 'dark'
    else return 'light'
  }
  
`  
}
          </pre>
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center"> 
           <h3>3. Let visitors manually choose a theme</h3>
           <p>As the old adage goes ‘Beauty is in the eye of the beholder.’ Not everyone will be comfortable with a dark theme and it would help your cause if you enable the option to toggle between the two. Imagine the plight of a hypermetropic uncle browsing through your website that’s in night mode. </p>
           <h4>The following widget is a working example</h4>
           <iframe title="Jsfiddle example to manually choose a theme" width="100%" height="300" src="https://jsfiddle.net/Baliga/gf6mzvxy/embedded/js,html,css,result/dark/?fontColor=pink" allowFullScreen="allowfullscreen" allowpaymentrequest="true" frameBorder="0"></iframe>
        </div> 
    </div> 
    <div className="row">
        <div className="column column-75 center"> 
           <h2 className="title-highlight">Browser Support</h2>
           <p>We have all faced it. </p>
           <p>The distressing compatibility issues that pop up while opening attachments that were created & modified in archaic versions. The same applies to the codes above as it might not run in certain versions or different browsers. To help you out, the following chart provides an outline in this regard. </p>
            <CanIUseEmbed1 />
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
           <p>Dark mode is here to stay, well, at least until a Jedi rises with a new format. </p>
           <p>The three methods mentioned above can definitely boost the appeal of your site. And, to know more about Night mode and the related topics, here are some links for you!.</p>
           <p>Until then, may the force be with you! 🖖</p>
        </div> 
    </div>
    {/* <div className="row">
        <div className="column column-75 center"> 
           <h4>Resources (3)</h4>
           <ul>
            <li><a rel="noopener noreferrer" href="https://www.kooslooijesteijn.net/blog/add-dark-mode-to-website" target="_blank">https://www.kooslooijesteijn.net/blog/add-dark-mode-to-website</a></li>
            <li><a rel="noopener noreferrer" href="https://in.mashable.com/tech/9192/heres-why-2019-was-the-year-of-dark-mode" target="_blank">https://in.mashable.com/tech/9192/heres-why-2019-was-the-year-of-dark-mode</a></li>
            <li><a rel="noopener noreferrer" href="https://medium.com/@somilsharma627/what-is-dark-mode-and-how-to-implement-it-7bf039576d6a" target="_blank">https://medium.com/@somilsharma627/what-is-dark-mode-and-how-to-implement-it-7bf039576d6a</a></li>
           </ul>

        </div> 
    </div> */}

    <BlogFooter BlogId={blogID} />
 
    </div>
  );
}

export default Blog1;
