import React, {useEffect} from 'react';  
import {HelmetHomeSEO} from '../seo'; 
import Footer from './Footer.js';

function Home() {

    useEffect(()=>{ 
        window.scrollTo(0, 0)
      });

  return (
    <>

     <HelmetHomeSEO /> 
        {/* <Maintainence /> */} 

        <div className="wrapper--50">   

            <div className="row">
                <div className="column"> 
                    <div className="banner-wrapper"> 
                    <img className="avatar--profile" src="./img/vishnu-baliga.png" alt="Cartoon Profile of Me, Vishnu Baliga" />
                    <h1>Vishnu Baliga</h1>
                    <h5>A CSS, HTML junkie who codes with Carnatic BGM, sips coffee while planning Marathons, and does pushups while watching Darth Vader reign.</h5>  
                    </div> 
                </div> 
            </div> 


        
            
            <div className="row">
                <div className="column"> 
                    <div className="category-item">   
                        <h5>What I am!</h5> 
                        <p>Oh, hi there! Well, feel free to have a look around. </p>
                        <p>A little about me? <br/>I code till I drop. So yes, I breathe CSS &amp; HTML.</p>
                        <p>At times, I pick up my camera and see the world through its lens. At other times, I love to pack my bag and hit the road. The 'I' in FITNESS would be me. The seventh day of the week is for Marathons. That, right there, is my muse. Turn my shoulder at folks who veto my Keto plans. And, excited about getting a four-legged furball real soon.</p>
                        {/* <p>Projects I've contributed to include <a target="_blank" rel="noopener noreferrer" href="https://www.spiketip.com/">SpikeTip</a>, <a target="_blank" rel="noopener noreferrer" href="https://svgtojsx.com/">SvgToJsx</a>, <a target="_blank" rel="noopener noreferrer" href="https://htmltojsx.in/">HtmlToJsx</a> and <a target="_blank" rel="noopener noreferrer" href="http://adam-marsden.co.uk/css-cheat-sheet/">CSS Cheat Sheet</a>. You can find more on <a target="_blank" rel="noopener noreferrer" href="https://github.com/VishnuBaliga">GitHub</a>.</p>  */}
                    </div> 
                </div> 
            </div>  

            <div className="row">
                <div className="column"> 
                    <div className="category-item">   
                        <h5>What I do</h5>
                        <p>I’m currently a Frontend engineer at <a target="_blank" rel="noopener noreferrer" href="https://www.factors.ai/">Factor.AI</a>, an end-to-end marketing analytics platform that integrates across data silos to deliver focussed AI-fueled actionable insights, at Bangalore. Part of the core team that’s building and scaling an AI-powered marketing analytics platform. Factors.ai has been chosen in the Google for Startups Accelerator India, 2020 program.</p>
                        <p>Previously worked with <a target="_blank" rel="noopener noreferrer" href="https://surveysparrow.com/">SurveySparrow</a>, a SaaS customer experience management platform, as a founding team member. Built &amp; scaled the entire product to its current stable version. <b>Founder &amp; Creator</b> of <a target="_blank" rel="noopener noreferrer" href="https://www.producthunt.com/posts/video-surveys-by-surveysparrow">&nbsp;Video Surveys</a> and Voice-driven Surveys, both of which helped the product to receive traction and cross impressive sales figures.</p>
                        <p>Are you a startup, agency, SMB, or an enterprise? Lovely to meet you!</p>
                        <p>If you’d like to build a website that compels the user to stay and be awed, let’s talk. I love to explore and implement what’s latest in technology. When you landed on this website, notice how quickly it rendered? Well, I rest my case. </p> 
                    </div> 
                </div> 
            </div>  

            <div className="row">
                <div className="column"> 
                    <div className="category-item">   
                        <h5>And, a little bit of Open Source</h5> 
                        <p>An avid contributor to Open Source projects. Some of the ones I have contributed include: </p>
                        <ul>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://spiketip.netlify.app">SpikeTip</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://svgtojsx.com/">SvgToJsx</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://htmltojsx.in/">HtmlToJsx</a></li>
                            {/* <li><a target="_blank" rel="noopener noreferrer" href="http://adam-marsden.co.uk/css-cheat-sheet/">CSS Cheat Sheet</a></li> */}
                        </ul>
                    </div> 
                </div> 
            </div>  

            <div className="row">
                <div className="column"> 
                    <div className="category-item">   
                        <h5>Blogs</h5> 
                        <p>I write about anything and everything where my heart lies. You can give it a whirl at  <a target="_blank" rel="noopener noreferrer"  href="https://medium.com/@baliga.vishnu">Medium</a> or right over here! </p>
                        {/* <p>My latest blog posts can be found on my <a target="_blank" href="https://medium.com/@baliga.vishnu">Medium</a> channel. My last post on here was: <Link to="/blog/">Blog</Link></p>  */}
                    </div> 
                </div> 
            </div>  


            <Footer />

        </div> 
        
    </>
  );
}

export default Home;
