import React,{useEffect} from 'react';    
import {BlogListHelmet,BlogList} from '../../seo';    
import BlogFooter from './BlogFooter';

const blogID = 1;
const ImgBaseUrl = `/img/blog/blog-102`;
const ImgBaseClass = `blog-img--large`;

 
function Blog2() {  
 
  useEffect(() => {
    (async () => {
        if ('loading' in HTMLImageElement.prototype) {
            const images = document.querySelectorAll("img.lazyload");
            images.forEach(img => {
                img.src = img.dataset.src;
            });
        } else {
          const script = document.createElement('script');
          script.src =
            'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
          document.body.appendChild(script);
        }
  })();
  });

  return (
    <div className="blog-container">  
    
    {/* importing SEO Details */}
    {BlogListHelmet(blogID)}

    <div className="row">
        <div className="column column-75 center title-container">

        <h1>{BlogList[blogID].title}</h1>
        <p>{BlogList[blogID].date}</p>
        
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center">

       <p>From college students looking to make some extra cash to professionals moonlighting or working full time, web design and development is a niche that has been thrust into the limelight of late. It takes skills and creativity to be a web designer or developer and new strategies are popping up at a rate faster than Dwayne ‘The Rock’ Johnson movies. It is, therefore, a herculean task for folks to stay on top of all the info. As Steve Jobs rightly said, “There’s always ‘one more thing’ to learn”. It is vital for both fledgling and veteran developers & designers to keep up with the latest trends in order to stay in touch with techniques that’ll allow the creative juices to flow.</p>
  
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h2>Best Places to Learn</h2>
            <p>There is an overwhelming amount of information pertaining to this area making it difficult to pinpoint the ones ideally suited for the needs of developers and designers. A good place to start would be to browse through blogs that are able to guide one through the process and that is updated regularly with new and exciting tricks of the trade. We have compiled a list of the top 10 web design and development blogs to follow. So, what are you waiting for? Let’s learn something new, today!!</p>
            
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>1. CSS-Tricks</h3>  
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/css-tricks.png`} alt="css-tricks.com" />
            <p>	<a href="https://css-tricks.com/" target="_blank" rel="noopener noreferrer">CSS-Tricks</a> has been going strong for over a decade and has a plethora of articles providing insights, tutorials, and updates pertaining to web design and development. It is built and run by website Guru Chris Coyier and features articles from numerous guest writers across all spectra of website building.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>2. Codrops</h3>  
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/codrops.png`} alt="codrops.com" />
            <p>Blog posts on <a href="https://tympanus.net/codrops/" target="_blank" rel="noopener noreferrer">Codrops</a> feature articles on the latest web trends and novel techniques for its implementation. It also has a playground that contains the latest experiments and plug-ins relating to web design and development.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>3. Smashing Magazine</h3> 
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/smashingmagazine.png`} alt="smashingmagazine.com" />
            <p>Since its inception in 2006, <a href="https://www.smashingmagazine.com/" target="_blank" rel="noopener noreferrer">Smashing Magazine</a> has gone onto host conferences and workshops in addition to publishing articles on anything and everything related to design and development. They also possess a library of over 60 ebooks for users to peruse. Be sure to check this one out!</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>4. GitHub Blog</h3> 
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/github-blog.png`} alt="github-blog.com" />
            <p><a href="https://github.blog/" target="_blank" rel="noopener noreferrer">GitHub Blog</a> is owned by Github inc, a subsidiary of Microsoft. It provides a comprehensive array of articles on tips and tricks to help you navigate through the intricacies of web design and development.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>5. UXPin</h3>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/uxpin-blog.png`} alt="uxpin-blog.com" />
            <p><a href="https://www.uxpin.com/" target="_blank" rel="noopener noreferrer">UXPin</a> is primarily a product design platform and its blogs revolve around topics that are often overshadowed or forgotten. Going through their blogs would be a clean way to polish your web designing and development skills. The articles here will provide instructions on techniques that will allow you to have greater control of your website and encourage a higher level of customization.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>6. Scotch.io</h3>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/scotch.png`} alt="scotch.com" />
            <p><a href="https://scotch.io/" target="_blank" rel="noopener noreferrer">Scotch.io</a> has courses and tutorials aimed at enhancing the ability of web developers and designers. Moreover, it is run by a vibrant community of coders and provides a platform to discuss the hurdles faced during implementation. Be sure to pay a visit to their community to have fun while learning.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>7. CodePen Blog</h3>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/codepen-blog.png`} alt="codepen-blog.com" />
            <p>One of the founders of <a href="https://codepen.io/" target="_blank" rel="noopener noreferrer">CodePen</a> is the same gentleman who created the first entry in our list and he intended this to be a playground to test the latest techniques in web design and development. <a href="https://blog.codepen.io/" target="_blank" rel="noopener noreferrer">CodePen blog</a> is the perfect place to start for those who want to hone their skills in a realtime environment.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>8. Web Design Weekly</h3>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/web-design-weekly.png`} alt="web-design-weekly.com" />
            <p><a href="https://web-design-weekly.com/" target="_blank" rel="noopener noreferrer">Web Design Weekly</a> as the name suggests, it is a weekly web design newsletter. It is an ideal platform for web developers and designers to stay updated and its content would resonate particularly well, for it’s written by experienced web designers.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>9. Sitepoint Blog</h3>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/sitepoint-blog.png`} alt="sitepoint-blog.com" />
            <p><a href="https://www.sitepoint.com/blog/" target="_blank" rel="noopener noreferrer">Sitepoint</a> publishes innovative articles on web design and development on a daily basis. They also have a monthly newsletter that you can subscribe to which provides a round-up on all the ‘happening’ things in web design and development. Talk about consistency! </p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center"> 
            <h3>10. Tutorialzine</h3>
            <img className={ImgBaseClass} className="lazyload" loading={`lazy`} data-src={`${ImgBaseUrl}/tutorialzine.png`} alt="tutorialzine.com" />
            <p><a href="https://tutorialzine.com/" target="_blank" rel="noopener noreferrer">Tutorialzine</a> publishes interesting articles to improve the skills of web designers and developers in addition to having an extensive collection of libraries and web experiments.</p>             
        </div> 
    </div>
    <div className="row">
        <div className="column column-75 center">  
            <p>The 10 blogs listed above is a holistic pool of information for web design and development. There are more out there, however, the 10 I’ve handpicked here are relevant to the needs of the market and reflect the tremors in the world of web design and development. Moreover, these blogs have received glowing testimonials from those involved in the same field and the considerable traffic to these sites is further testament to their appeal. A few hours on these blogs could very well result in your skills growing tenfold whilst providing the opportunity to interact with a community of like-minded individuals.</p>             
        </div> 
    </div> 


    <BlogFooter BlogId={blogID} />

    </div>
  );
}

export default Blog2;
