import React, {useEffect} from 'react';  
import { HelmetBlogHomeSeo} from '../../seo';
import {Route, Switch, Redirect } from 'react-router-dom';
import Footer from '../Footer.js';
import {BlogList} from '../../seo/';
import BlogListing from './BlogListing';
import Blog1 from './Blog1';
import Blog2 from './Blog2';
import Blog3 from './Blog3';
import Blog4 from './Blog4';
import Blog5 from './Blog5';
import Blog6 from './Blog6';
 

const routes = [{  
  path: `/blog/${BlogList[0].url}`,
  component: Blog1,
}, 
{
  path: `/blog/${BlogList[1].url}`,
  component: Blog2,
},
{
  path: `/blog/${BlogList[2].url}`,
  component: Blog3,
},
{
  path: `/blog/${BlogList[3].url}`,
  component: Blog4,
},
{
  path: `/blog/${BlogList[4].url}`,
  component: Blog5,
},
{
  path: `/blog/${BlogList[5].url}`,
  component: Blog6,
},
];


const BlogHome = () =>{
 
  return (
      <div className="wrapper--50">
          <HelmetBlogHomeSeo /> 
          <div className="row">
                <div className="column"> 
                    <div className="banner-wrapper"> 
                    <h1>Vishnu Baliga</h1>
                    <h5>This is the space where I kinda scribble about the things I’m passionate about. So you’ll find a blog here about web development, one there about Marathons, and another about Fitness. Read away!</h5>  
                    </div> 
                </div> 
            </div> 
            <div className="mtb--2em">
              <BlogListing /> 
            </div> 
            <Footer />   
    </div>
    )
}
function Blog() {
 
  useEffect(()=>{ 
    window.scrollTo(0, 0)
  });
  const blogRoutes = routes.map(({path, component}, key) => <Route exact path={path} component={component} key={key} />);

  return (
    <div>  
 
          <Switch> 
              <Route path="/blog/" exact component={BlogHome}/>    
              {blogRoutes}
              <Redirect to='/' />
          </Switch>  
    </div>
  );
}

export default Blog;
