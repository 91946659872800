import React from 'react';
import classnames from 'classnames';

function Footer({compressed}){ 

    return (
        <footer className={classnames({
            footer: true,
            compressed
        })}>
                <p className="small">© Copyright 2020 Vishnu Baliga</p>
            </footer>
    )
  }

  export default Footer;
