import React,{useEffect} from 'react';    
import {BlogListHelmet,BlogList} from '../../seo';    
import BlogFooter from './BlogFooter';
// import {Link } from 'react-router-dom';

const blogID = 4;
// const ImgBaseUrl = `/img/blog/blog-105`;
// const ImgBaseClass = `blog-img--large`;

 
function Blog4() {  
 
//   useEffect(() => {
//     (async () => {
//         if ('loading' in HTMLImageElement.prototype) {
//             const images = document.querySelectorAll("img.lazyload");
//             images.forEach(img => {
//                 img.src = img.dataset.src;
//             });
//         } else {
//           const script = document.createElement('script');
//           script.src =
//             'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
//           document.body.appendChild(script);
//         }
//   })();
//   });

  return (
    <div className="blog-container">  
    
    {/* importing SEO Details */}
    {BlogListHelmet(blogID)}

    <div className="row">
        <div className="column column-75 center title-container">

        <h1>{BlogList[blogID].title}</h1>
        <p>{BlogList[blogID].date}</p>
        
        </div> 
    </div>

    <div className="row">
        <div className="column column-75 center"> 
            <p>GUIs have made monumental leaps since the inception of the <a href="https://history-computer.com/ModernComputer/Software/Sketchpad.html" target="_blank">humble Sketchpad</a>. However, it’s the simplest tools that often spruce up your website from mediocrity to excellence. One such GUI element that holds this distinction is the humble tooltip. For the uninitiated, the tooltip is the little hovering box that pops up when you float your cursor over an item without clicking on it. A tooltip is typically used to provide information on an item or instructions to its purpose.</p>
        </div> 
    </div>
    
    <div className="row">
        <div className="column column-75 center"> 
            <p>At first glance, tooltip might seem like an inelegant entity not worth investing time in when creating your website. On the contrary, it is like the last Lego block needed to complete your model set: a small piece without which the whole model falters. Tooltip allows visitors of your website to stay on the same page when browsing thus effectively eliminating the need for a ‘Help’ section. This enhances user experience and ensures that the point you are communicating through your website is delivered seamlessly without any needless interruptions. Moreover, they are a fine addition as it does not crowd out the content you are presenting whilst giving the viewer the choice to make use of the tooltip. These minute improvements in user experience could lead to a positive cumulative effect at the end of the day. I have outlined the methods to build your own CSS only tooltip below. I have chosen to demonstrate using CSS over JS to reduce the unwanted JavaScript overhead.</p>
        </div> 
    </div> 


    <div className="row">
        <div className="column column-75 center"> 
            <h2 className="title-highlight">How to Build a DIY CSS tooltip?</h2>
            <p>You don’t have to rummage through StackExchange to come across a decent free tooltip. </p>
            <p>What if I say you can make one yourself?</p>
            <p>Here’s a DIY CSS tooltip that you can use. Alternatively, you can use SASS or Less, as per your preference. </p>
            
        </div> 
    </div> 

    <div className="row">
        <div className="column column-75 center"> 
            <h4>1. Define HTML elements with tooltip related attributes</h4>
            <p>You can define any HTML elements such as button, anchor, span, and then add the attribute to enable a tooltip on them. In the following example, I’ve added <code>tooltip-title</code> attribute to the elements.</p>
            <pre> { `
  <button tooltip-title="I'm a Tooltip!">Hover Me!</button> 
            ` } </pre> 
        </div> 
    </div>  
    
    <div className="row">
        <div className="column column-75 center"> 

            
            <h4>2. Add styling to your tooltip.</h4>
            <p>We are styling the tooltip in the <code>:after</code> pseudo-element of the selected element using CSS.</p> 
            <pre> { `
 
  [tooltip-title] {
    /* base style for the element */
    position: relative;
    cursor: pointer;
  }
  
  [tooltip-title]::after { 
  
    /* Takes the content from tooltip-title attribute */
    content: attr(tooltip-title);
    
    /* should be hidden initially */
    opacity: 0;
    
    /* basic styling tooltip */
    background: #222;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 12px;
    padding: 0.5em 1em;
    white-space: nowrap;
    z-index: 10;
    
    /* Positioning tooltip */ 
    position: absolute;
    bottom: 100%;
    left: 50%;
      
    /* Basic effects on tooltip */   
    transition: all 0.2s ease-out 0.2s;
    transform: translate(-50%, 10px);
    transform-origin: top;
        
  }
    
  [tooltip-title]:hover::after {
    /* Making it visible on hover */
    opacity: 1; 
  }
  
  [tooltip-title]:hover::after {
    /* Effects on hover */   
    transform: translate(-50%, 0);
  }
            ` } </pre>

        </div> 
    </div>
    
    <div className="row">
        <div className="column column-75 center"> 
            <h4>3. Advanced tooltip features (optional).</h4>
            <p><strong>3.1 Position your tooltips</strong></p>
            <p>In addition to its looks, you can define the position of the tooltip as well. For instance, top, bottom, left, right, top-right, top-left, bottom-right, bottom-left, etc.</p>
            <p>Shown below is an example of making a tooltip display according to the position mentioned in the <code>tooltip-position</code> attribute. (<code>tooltip-position='bottom'</code> is shown as the example, you can do it similarly with other possible positions.)</p>
            <pre> { `
  <button tooltip-title="I'm a Tooltip!" tooltip-position="bottom">Hover Me!</button> 
            ` } </pre> 
            <pre> { `
  [tooltip-position='bottom']::after {
    left: 50%;
    margin-top: 12px;
    top: 100%;
    transform: translate(-50%, -10px);
  }
  [tooltip-position='bottom']::before {
    left: 50%;
    margin-top: 5px;
    top: 100%;
    transform: translate(-50%, -10px);
  }
  [tooltip-position='bottom']:hover::after, [tooltip-position='bottom']::after {
    transform: translate(-50%, 0);
  }
  [tooltip-position='bottom']:hover::before, [tooltip-position='bottom']::before {
    transform: translate(-50%, 0);
  }

            ` } </pre> 
        </div> 
    </div>  


    <div className="row">
        <div className="column column-75 center"> 
            <p><strong>3.2 Colour your tooltips</strong></p>
            <p>You can define a color to your tooltip depending on the message you are displaying (example: success/green, warning/yellow, error/red, etc.)</p> 
            <p>In the example below, I'm adding <code>tooltip-color</code> attribute to the element to define the required color. (<code>tooltip-color='warning'</code> is shown as the example, you can do it similarly with other possible colors.)</p>
            <pre> { `
  <button tooltip-title="I'm a Tooltip!" tooltip-color="warning">Hover Me!</button> 
            ` } </pre> 
            <pre> { `
  [tooltip-color="warning"]:hover::after {  
    background-color: #ffc107;
  }
            ` } </pre> 
        </div> 
    </div> 

    <div className="row">
        <div className="column column-75 center"> 
            <p><strong>3.3 Size your tooltips</strong></p>
            <p>You can define size to your tooltip depending on the length of the message you are displaying (example: small, medium, large, extra-large, auto-width, etc.)</p> 
            <p>In the below example I'm adding the <code>tooltip-length</code> attribute to the element to define the width of the tooltip. (<code>tooltip-length='lg'</code> is shown as the example, you can do it similarly with other possible lengths.)</p>
            <pre> { `
  <button tooltip-title="I'm a Tooltip!" tooltip-length="lg">Hover Me!</button> 
            ` } </pre> 
            <pre> { `
  [tooltip-length='lg']::after {
    white-space: normal;
    width: 250px;
  }
            ` } </pre> 
        </div> 
    </div> 

    <div className="row">
        <div className="column column-75 center"> 
            <p>You can have a look at the working demo of the above example here: <h2><a href="https://spiketip.com/" target="_blank">Tooltip Preview</a></h2> </p>
            <p>Also, I've created an open source tooltip library called <a href="https://spiketip.com/" target="_blank">SpikeTip </a>, which you can use in your website/ fork/ and then modify it the way you want.</p>
            <p>For those of you who want to directly incorporate the tooltip into your projects, I have created an NPM package and you’ll find the installation guide here: <a href="https://www.npmjs.com/package/spiketip-tooltip" target="_blank">SpikeTip - NPM Package</a>.</p>
        </div> 
    </div>  

    <div className="row">
        <div className="column column-75 center"> 
            <p>Tooltips would definitely be a pertinent addition to your website and I hope the DIY mini-tutorial above helped you to create stylized tooltips of your choice.</p>
        </div> 
    </div> 


    <BlogFooter BlogId={blogID} />

    </div>
  );
}

export default Blog4;
