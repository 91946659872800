import React from 'react'; 
import {Helmet} from 'react-helmet';

const HelmetHomeSEO = () =>{
    return(
    <Helmet> 
        <title>Vishnu Baliga | Front-end Developer | Marathoner | CSS Junkie</title>
        <meta name="title" content="Vishnu Baliga | Front-end Developer | Marathoner | CSS Junkie" />
        <meta name="description" content=" Baliga here, a passionate Front-end Developer. I help startups, SMBs, &amp; agencies to build &amp; scale websites with exceptional user experience. Let’s connect, now!" />
 
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vishnubaliga.com/" />
        <meta property="og:title" content="Vishnu Baliga | Front-end Developer | Marathoner | CSS Junkie" />
        <meta property="og:description" content=" Baliga here, a passionate Front-end Developer. I help startups, SMBs, &amp; agencies to build &amp; scale websites with exceptional user experience. Let’s connect, now!" />
        <meta property="og:image" content="https://vishnubaliga.com/img/vishnu-baliga-thumbnail.png" />
 
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://vishnubaliga.com/" />
        <meta property="twitter:title" content="Vishnu Baliga | Front-end Developer | Marathoner | CSS Junkie" />
        <meta property="twitter:description" content=" Baliga here, a passionate Front-end Developer. I help startups, SMBs, &amp; agencies to build &amp; scale websites with exceptional user experience. Let’s connect, now!" />
        <meta property="twitter:image" content="https://vishnubaliga.com/img/vishnu-baliga-thumbnail.png" />
    </Helmet>
    )
} 
const HelmetBlogHomeSeo = () =>{
    return(
        <Helmet>
        <title>Blog | Driving Passion At Everything I Do | Vishnu Baliga</title>
        <meta name="description" content="Over here in my Blog, you’ll find content pieces ranging from CSS, HTML, Browsers, Marathons, Fitness. Pretty much everything where my passion lies. Read away!" />
        <meta name="keywords" content="fitness blog, HTML blogs, CSS blogs, marathon blogs, web development blogs, developer blogs, front-end development blogs" />
         
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vishnubaliga.com/blog" />
        <meta property="og:title" content="Blog | Driving Passion At Everything I Do | Vishnu Baliga" />
        <meta property="og:description" content="Over here in my Blog, you’ll find content pieces ranging from CSS, HTML, Browsers, Marathons, Fitness. Pretty much everything where my passion lies. Read away!" />
        <meta property="og:image" content="https://vishnubaliga.com/img/vishnu-baliga-blog-thumbnail.png" />
 
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://vishnubaliga.com/blog" />
        <meta property="twitter:title" content="Blog | Driving Passion At Everything I Do | Vishnu Baliga" />
        <meta property="twitter:description" content="Over here in my Blog, you’ll find content pieces ranging from CSS, HTML, Browsers, Marathons, Fitness. Pretty much everything where my passion lies. Read away!" />
        <meta property="twitter:image" content="https://vishnubaliga.com/img/vishnu-baliga-blog-thumbnail.png" />

    </Helmet>
    )
} 

const BlogList = [
    {
        id:100,
        pageTitle: '3 Insanely Simple Ways to Switch Your Website To Dark Mode - Vishnu Baliga',
        title: '3 Insanely Simple Ways to Switch Your Website To Dark Mode',
        url:'simple-ways-to-switch-your-website-to-dark-mode',
        metaTags:'dark mode, night mode, dark theme, ios dark mode, android dark mode',
        desc:'Wish to have Dark Mode for your website? Here’re 3 insanely simple ways to do so! Know the A-Z of dark theming along with the FREE code snippet. Embed, now!',
        date:'March 10, 2020',
        category:'Tech',
        thumbnail:'https://vishnubaliga.com/img/blog/blog-101/dark-mode-website-thumbnail.jpg',
        active: true,

    },   
    {
        id:101,
        pageTitle: 'Top 10 Web Design and Development Blogs to Follow in 2020 - Vishnu Baliga',
        title: 'Top 10 Web Design and Development Blogs to Follow in 2020',
        url:'best-web-design-and-development-blog-to-follow',
        metaTags:'web design and development blog, web design blog, best web design, best web design and development blogs to follow, web development blogs, blogs about web development, blogs on web development, web development blogs 2020',
        desc:'Here’s a list of the Top 10 Web Design and Development Blogs to follow in 2020. Read through the best tips and polish your web designing & development skills, now!',
        date:'March 25, 2020',
        category:'Tech',
        thumbnail:'https://vishnubaliga.com/img/blog/blog-102/css-tricks.png',
        active: true,

    },   
    {
        id:102,
        pageTitle: '5 Ways With Which I Trained A Team For Their First Marathon - Vishnu Baliga',
        title: '5 Ways With Which I Trained A Team For Their First Marathon',
        url:'5-ways-with-which-i-trained-a-team-for-their-first-marathon',
        metaTags:'first marathon, train first marathon, train for your first marathon, how to train for first marathon, train for your first half marathon',
        desc:'We run together, we train together. These are the 5 ways with which I trained a team for their First Marathon. Check out these 5 tips before running your first Marathon! ',
        date:'April 2, 2020',
        // category:'Running',
        category:'all',
        thumbnail:'https://vishnubaliga.com/img/blog/blog-103/TCS-marathon-Vishnu-baliga.jpg',
        active: true,

    },   
    {
        id:103,
        pageTitle: 'Did Someone Say Surveys Are Boring? Hello, Video Surveys - Vishnu Baliga',
        title: 'Did Someone Say Surveys Are Boring? Hello, Video Surveys',
        // url:'move-from-boring-surveys-to-video-surveys',
        url:'video-surveys',
        metaTags:'video surveys, online video surveys, online surveys, boring surveys, surveys are boring',
        desc:'Did someone tell you online surveys are boring? That’s probably because they haven’t engaged with Video Surveys. Here’s the story of how I built Video Surveys for my company!',
        date:'May 25, 2020',
        category:'Tech',
        thumbnail:'https://vishnubaliga.com/img/blog/blog-103/TCS-marathon-Vishnu-baliga.jpg',
        active: false,

    },   
    {
        id:104,
        pageTitle: 'SpikeTip | Free CSS Tooltip | HTML Tooltip | Vishnu Baliga',
        title: '3 Insanely Simple Steps To DIY Tooltips',
        url:'diy-css-tooltips-spiketip',
        metaTags:'free tooltip, best tooltip, HTML tooltip, css tooltip, free css tooltip, tooltip css, DIY, DIY tooltips',
        desc:'SpikeTip is a free HTML Tooltip that you can readily use for your website with just a copy ‘n paste. Don’t go rummaging for the best tooltip. Try Spiketip, now!',
        date:'May 7, 2020',
        category:'Tech',
        thumbnail:'https://vishnubaliga.com/img/blog/blog-105/diy-tooltips-thumbnail.png',
        active: true,

    },   
    {
        id:105,
        pageTitle: 'Scappi For Chennai | Flood Relief Initiative | Vishnu Baliga',
        title: 'Scappi For Chennai',
        url:'scappi-for-chennai',
        metaTags:'chennai floods, scappi, geolocation apps, location tracker app, relief camps app, flood volunteer app',
        desc:'Scappi for Chennai is an app that stemmed from Chennai Floods to help the volunteers streamline relief works & make it efficient. Try geo-location app Scappi!',
        date:'May 17, 2020',
        category:'Tech',
        thumbnail:'https://vishnubaliga.com/img/blog/blog-106/scappi-thumbnail.png',
        active: true,

    },   
]; 

const BlogListHelmet = (id) => { 
        if(BlogList[id]){
            return (
            <Helmet> 

                <title>{BlogList[id].pageTitle}</title>
        <meta name="title" content={BlogList[id].pageTitle} />
        <meta name="description" content={BlogList[id].desc} />
        <meta name="keywords" content={BlogList[id].metaTags} /> 
 
        <meta property="og:type" content="website" />
        <meta property="og:url" content={BlogList[id].url} />
        <meta property="og:title" content={BlogList[id].pageTitle} />
        <meta property="og:description" content={BlogList[id].desc} />
        <meta property="og:image" content={BlogList[id].thumbnail} />
 
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={BlogList[id].url} />
        <meta property="twitter:title" content={BlogList[id].pageTitle} />
        <meta property="twitter:description" content={BlogList[id].desc} />
        <meta property="twitter:image" content={BlogList[id].thumbnail} />

            </Helmet>
            )   
        }
        else{
            console.error("Invalid Blog Id");
        }
};


export {
    HelmetHomeSEO,
    HelmetBlogHomeSeo,
    BlogList,
    BlogListHelmet,
}